<template>
<section>
    <v-container>
        <v-row justify="start">
          <v-toolbar-title
            class="grey--text text--darken-4 font-weight-black my-5 ml-12"
            >
            Registered Control Drug List
          </v-toolbar-title>
         <v-spacer />
          <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
          >
          </v-text-field>
           <v-spacer />
          <div class="text-center">
            <v-menu bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  append-icon="filter_alt"
                  v-model="searchResident"
                  label="Filter By Resident"
                  single-line
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                  />
              </template>
              <v-list style="max-height: 100px" v-model="searchResident">
                <v-list-item
                  v-for="(item, index) in residents"
                  :key="index"
                  v-bind="item"
                >
                  <v-list-item-title  @click="controlDrugByResident(item)">{{ item.fullName }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="controlDrug"
          :options.sync="options"
          :server-items-length="totalControlDrug"
          :loading="loading"
          loading-text="Loading ..... Please wait"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 20, 40],
            itemsPerPageText: 'Control Drug per page',
          }"
          update:options
          class="elevation-23"
        >
            <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-dialog v-model="dialog" max-width="500px">

                      <v-card>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.name" label="Control Drug name"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.directive" label="Directive"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.pharmacy" label="Pharmacy"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4" class="mr-12">
                                <label for="quantity-order"> Quantity Order </label><br>
                                <input v-model="editedItem.quantityOrder" type="number" name="quantity-order" id="input"/>
                              </v-col>
                              <v-col cols="12" sm="6" md="4" class="ml-12 pl-2">
                                 <label for="quantity-receive"> Quantity Receive </label><br>
                                <input v-model="editedItem.quantityReceived" type="number" name="quantity-receive" id="input"/>
                              </v-col>
                              <v-row>
                                  <v-col cols="12" sm="6" md="5">
                                        <v-menu
                                          v-model="editedItem.menu1"
                                          :close-on-content-click="false"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="290px"
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-text-field
                                              v-model="editedItem.startDate"
                                              label="Start Date"
                                              prepend-icon="event"
                                              readonly
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-datetime-picker
                                            v-model="editedItem.startDate" timePickerFormat="24hr"
                                            dateFormat="dd-MM-yyyy">
                                          </v-datetime-picker>
                                        </v-menu>
                                      </v-col>

                                      <v-col cols="12" sm="6" md="5" class="ml-10">
                                        <v-menu
                                          v-model="editedItem.menu2"
                                          :close-on-content-click="false"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="200px"
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-text-field
                                              v-model="editedItem.endDate"
                                              label="End Date"
                                              prepend-icon="event"
                                              readonly
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-datetime-picker no-title scrollable
                                            v-model="editedItem.endDate" timePickerFormat="24hr"
                                            dateFormat="dd-MM-yyyy">
                                          </v-datetime-picker>
                                        </v-menu>
                                      </v-col>
                                </v-row>
                                <v-col cols="12" sm="6" md="4" class="mr-12">
                                <v-text-field v-model="editedItem.witness" label="Witness"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text  @click="close">Cancel</v-btn>
                          <v-btn color="blue darken-1" text  @click="save">Save</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.action="{ item }">
                   <v-row justify="space-around">
                      <span class="group pa-2">
                          <v-icon
                            v-if="$can('update')"
                            small
                            class="mr-2"
                            @click="editItem(item)"
                          >
                            edit
                          </v-icon>
                          <v-icon
                            v-if="$can('delete')"
                            small
                            @click="deleteItem(item)"
                          >
                            delete
                          </v-icon>
                          <v-icon
                            v-if="$can('read')"
                            medium @click="detail_control_drug(item)">info</v-icon>
                      </span>
                   </v-row>
                </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-2 align-center"  v-if='selectedId'>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                class="mr-3"
                v-on="on"
                @click="addNew()"
              >
              Register
              </v-btn>
            </template>
            <span>Register new control drug</span>
          </v-tooltip>
         </div>
    </v-container>
</section>
</template>
<script>
import {  mapActions } from 'vuex'
import controlDrugApi from '../../services/api/ControlDrugRegisterAPI';
import residentApi from '@/services/api/ResidentApi';
import moment from 'moment';
export default {
    name: 'controlDrugRegisterList',
    components: {
    },
    data () {
        return {
            dialog: false,
            controlDrug: [],
            residents: [],
            searchResident: '',
            totalControlDrug: 0,
            loading: true,
            options: {},
            search: '',
            residentId: '',
            selectedId:'',
             headers: [
                { text: 'Control Drug Name', value: 'name', class: 'success--text font-weight-bold' },
                { text: 'Resident', value: 'resident.fullName', class: 'success--text font-weight-bold' },
                { text: 'Directive', value: 'directive',  sortable: false, class: 'success--text font-weight-bold' },
                { text: 'Receive Time', value: 'receiveDateTime', class: 'success--text font-weight-bold' },
                { text: 'Quantity Order', value: 'quantityOrder', class: 'success--text font-weight-bold' },
                { text: 'Quantity Receive', value: 'quantityReceived', class: 'success--text font-weight-bold' },
                { text: 'Actions', value: 'action', sortable: false, class: 'success--text font-weight-bold' },
            ],
              editedItem: {
                name: '',
                directive: '',
                pharmacy: '',
                quantityOrder: '',
                quantityReceived:'',
                startDate: null,
                endDate: null,
                menu1: false,
                menu2: false,
                witness:'',
              },
              defaultItem: {
                name: '',
                directive: '',
                pharmacy: '',
                quantityOrder: '',
                quantityReceived:'',
                startDate: null,
                endDate: null,
                menu1: false,
                menu2: false,
                witness:'',
              },
        }
    },
    methods: {
        ...mapActions([
            'update_control_drug',
        ]),
        editItem (item) {
            this.editedItem = Object.assign({}, item)
            this.defaultItem.startDate = item.startDate;
            this.defaultItem.endDate = item.endDate;
            this.dialog = true
        },

        deleteItem (item) {
          confirm('Are you sure you want to delete this control drug?') && this.delete_control_drug(item)
          const index = this.controlDrug.indexOf(item)
          this.controlDrug.splice(index, 1)
        },

        close () {
          this.dialog = false
          setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          }, 300)
        },

        save () {
            delete this.editedItem.resident;
            delete this.editedItem.createdOn;
            delete this.editedItem.modifiedOn;
            delete this.editedItem.receiveDateTime;
            delete this.editedItem.user;
            if (this.defaultItem.startDate != this.editedItem.startDate){
               this.editedItem.startDate = this.formatDate(this.editedItem.startDate);
            }
            if (this.defaultItem.endDate != this.editedItem.endDate){
              this.editedItem.endDate=this.formatDate(this.editedItem.endDate);
            }
            this.update_control_drug({...this.editedItem})
          this.close()
        },
        get_registered_control_drug_list () {
          this.loading = true
          return new Promise((resolve, reject) => {
              const { sortBy, sortDesc, page, itemsPerPage } = this.options
              if (sortDesc&& sortDesc.length && sortDesc[0] == false){
                var orde = 'asc'
              }
              else{
                 orde = 'desc'
              }
              if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                var homeId = ''
              }
              else{
                homeId = localStorage.getItem('homeId')
              }
              controlDrugApi.getRegisteredControlDrug({page: page, count:itemsPerPage, id:'', q:'', orderBy: sortBy && sortBy[0], order: orde})
                .then(controlDrug => {
                    if (homeId.toString().length > 0 ){
                        this.controlDrug = controlDrug.result.filter(controlD => controlD.resident.home.homeId==homeId)
                        this.totalControlDrug = this.controlDrug.length
                    }
                    else{
                        this.controlDrug = controlDrug.result
                        this.totalControlDrug = controlDrug.count
                    }
                })
                .catch(error => {
                    return reject(error)
                })
              setTimeout(() => {
                this.loading = false
                }, 1000)
          })
        },
        delete_control_drug(item) {
            controlDrugApi.deleteRegisteredControlDrug(item.id)
                .then(controlDrug => {
                    return ("deleted" + controlDrug);
                })
        },
         detail_control_drug(controlDrug) {
          this.$router.push({ name: 'controlDrugDetail', params: {residentId:controlDrug.resident.id, controlId:controlDrug.id}})
        },
        formatDate(date) {
          return moment(date).format('DD-MM-YYYY HH:mm')
        },
        get_resident_list () {
              return new Promise((resolve, reject) => {
                  if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                    var homecode = ''
                  }
                  else{
                    homecode = localStorage.getItem('homeCode')
                  }
                  residentApi.getResidents({page:'', count:'', home:homecode, q:''})
                    .then(residents => {
                        this.residents = residents.result
                        this.totalResidents = residents.count
                    }).catch(error => {
                        return reject(error)
                    })
                  setTimeout(() => {
                    this.loading = false
                    }, 1000)
              })
        },
      controlDrugByResident(resident) {
          var id = resident.id;
          this.selectedId = resident.id;
          this.searchResident = resident.fullName
          return new Promise((resolve, reject) => {
                  controlDrugApi.getRegisteredControlDrug({page:'', count:'', id:id, q:''})
                    .then(controlDrug => {
                       this.controlDrug = controlDrug.result
                        this.totalControlDrug = controlDrug.count
                    })
                    .catch(error => {
                      return reject(error)
                    })
                })
      },
      addNew() {
        this.$router.push({ name: 'createControlDrugRegister', params: {residentId:this.selectedId}});
      }
    },
    created() {
      // this.residentId = this.$route.params.residentId;
      this.get_registered_control_drug_list();
    },
    beforeMount(){
      this.get_resident_list();
    },
    watch: {
        options: {
          handler () {
            this.get_registered_control_drug_list()
                .then(data => {
                    this.controlDrug = data.result
                    this.totalControlDrug = data.count
                })
          },
          deep: true,
        },
        '$route.params.residentId': {
          handler () {
            this.residentId = this.$route.params.residentId;
            this.get_registered_control_drug_list()
                .then(data => {
                    this.controlDrug = data.result
                    this.totalControlDrug = data.count
                })
          },
          deep: true,
          immediate: true
        },
        dialog (val) {
          val || this.close()
        },
        editedItem: function() {
          this.get_registered_control_drug_list()
                .then(data => {
                    this.controlDrug = data.result
                    this.totalControlDrug = data.count
                })
        },
        search: function() {
          this.searchResident = ''
          this.selectedId = ''
          if(this.search.length > 0){
            if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                    var homeId = ''
                  }
                  else{
                    homeId = localStorage.getItem('homeId')
                  }
            return new Promise((resolve, reject) => {
                    controlDrugApi.getRegisteredControlDrug({page:'', count:'', id:'', q:this.search})
                      .then(controlDrug => {
                          if (homeId.toString().length > 0 ){
                            this.controlDrug = controlDrug.result.filter(controlD => controlD.resident.home.homeId==homeId)
                            this.totalControlDrug = this.controlDrug.length
                          }
                          else{
                            this.controlDrug = controlDrug.result
                            this.totalControlDrug = controlDrug.count
                          }
                      })
                      .catch(error => {
                          return reject(error)
                      })
                })
          }
          if(this.search.length <= 0){
            this.get_registered_control_drug_list()
                .then(data => {
                    this.controlDrug = data.result
                    this.totalControlDrug = data.count
                })
          }
        },
        searchResident: function(){
            if(this.searchResident.length <= 0){
              this.selectedId = ''
            this.get_registered_control_drug_list()
                .then(data => {
                    this.controlDrug = data.result
                    this.totalControlDrug = data.count
                })
            this.get_resident_list();
          }
          else if(this.searchResident.length >0){
              return new Promise((resolve, reject) => {
                  if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                    var homecode = ''
                  }
                  else{
                    homecode = localStorage.getItem('homeCode')
                  }
                  residentApi.getResidents({page:'', count:'', home:homecode, q:this.searchResident})
                    .then(residents => {
                        this.residents = residents.result
                        this.totalResidents = residents.count
                    }).catch(error => {
                        return reject(error)
                    })
                  setTimeout(() => {
                    this.loading = false
                    }, 1000)
              })
          }
        },
    },

}
</script>
<style lang="scss">
  #input {
    width:70%;
  }
  .table-footer-prepend {
    margin-top: -58px;
    height: 58px;
    margin-left: 400px;
  }
</style>
